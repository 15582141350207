<template>
  <div
    style="max-height: 60vh; overflow: hidden; overflow-y: auto"
    class="filtersSettings"
  >
    <div class="filtro-item mt-2">
      <!-- <div v-if="$store.state.uf === 'NA'"> -->
      <UF-combobox :filter="filtro" :outlined="true" />
      <color-picker
        v-if="filtro.uf.length"
        :defaultColor="filtersColor.uf"
        @onChange="(event) => changeMapColor(event, 'uf')"
      ></color-picker>
    </div>
    <div class="filtro-item">
      <div>
        <ComboboxTerritorios :filter="filtro"></ComboboxTerritorios>
      </div>
      <!-- <div class="mt-5">
        <v-select
          v-model="filtro.tipoTer"
          color="primary"
          dense
          outlined
          :hide-details="true"
          click:prepend
          :items="['E', 'OU']"
          label="Tipo de filtragem"
        >
          <template v-slot:prepend>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-information</v-icon></span
                >
              </template>
              <span
                >Diga o mecanismo de filtro para o território. Filtros com E
                trará somente territórios que possuem os dois. Filtros com OU
                trará o que possui um ou outro.
              </span>
            </v-tooltip>
          </template>
        </v-select>
      </div> -->

      <color-picker
        v-if="filtro.territorio.length"
        :defaultColor="filtersColor.region"
        @onChange="(event) => changeMapColor(event, 'region')"
      ></color-picker>
    </div>
    <div class="filtro-item">
      <ComboboxTerStatus :filter="filtro"></ComboboxTerStatus>
      <color-picker
        v-if="filtro.statusTer.length"
        :defaultColor="filtersColor.relacionamento"
        @onChange="(event) => changeMapColor(event, 'relacionamento')"
      ></color-picker>
    </div>
    <div class="filtro-item mt-2">
      <div>
        <ComboboxTerCategoria :filter="filtro"></ComboboxTerCategoria>
      </div>
      <!-- <div class="mt-5">
          <v-select
            v-model="filtro.tipoCat"
            color="primary"
            dense
            outlined
            :hide-details="true"
            click:prepend
            :items="['E', 'OU']"
            label="Tipo de filtragem"
          >
            <template v-slot:prepend>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon color="primary">mdi-information</v-icon></span
                  >
                </template>
                <span
                  >Diga o mecanismo de filtro para a categoria. Filtros com E
                  trará somente categorias que possuem os dois. Filtros com OU
                  trará o que possui um ou outro.
                </span>
              </v-tooltip>
            </template>
          </v-select>
        </div> -->
      <color-picker
        v-if="filtro.terCategoria.length"
        :defaultColor="filtersColor.eixo"
        @onChange="(event) => changeMapColor(event, 'eixo')"
      ></color-picker>
    </div>

    <div>
      <municipio-combobox :filter="filtro" :outlined="true" />
      <color-picker
        v-if="filtro.municipio.codigoIbge"
        :defaultColor="filtersColor.municipio"
        @onChange="(event) => changeMapColor(event, 'municipio')"
      ></color-picker>
    </div>
  </div>
</template>
<script>
import { UFCombobox } from "@/modules/uf";

import { ColorPicker } from "@/components";
import ComboboxTerStatus from "@/modules/territorios/components/ComboboxTerStatus.vue";
import ComboboxTerCategoria from "@/modules/territorios/components/ComboboxTerCategoria.vue";
import ComboboxTerritorios from "@/modules/territorios/components/ComboboxTerritorios.vue";
import { MunicipioCombobox } from "@/modules/municipio";
import { mapGetters, mapActions } from "vuex";
import { territoriosAPI } from "@/apis/territorios";

export default {
  name: "FiltrosTerritorios",
  components: {
    ColorPicker,
    ComboboxTerStatus,
    ComboboxTerCategoria,
    ComboboxTerritorios,
    MunicipioCombobox,
    UFCombobox,
  },
  props: {
    filtersDefaultColor: { type: Object, default: () => {} },
  },
  data() {
    return {
      territorios: [],
      filtro: {
        statusTer: [],
        terCategoria: [],
        territorio: [],
        municipio: {},
        uf: [],
      },
      qtdStatusTer: 0,
      qtdTer: 0,
      qtdTerCategoria: 0,
      qtdMunicipio: 0,
      qtdUf: 0,
      filtersColor: this.filtersDefaultColor,
    };
  },
  computed: {
    ...mapGetters(["mapa", "isSala"]),
  },
  async mounted() {
    try {
      const territorioResponse = await territoriosAPI.list();
      this.territorios = territorioResponse.data;
    } catch (error) {
      console.log("🚀 ~ mounted ~ error:", error);
      this.$notification.error("Erro ao carregar a lista de territórios");
    }
  },
  methods: {
    ...mapActions([
      "setMapaVariables",
      "limparMapaFixo",
      "setLoading",
      "resetarMapaFixo",
    ]),
    limpar() {
      this.setLoading(true);
      this.limparMapaFixo();
      this.filtro = {
        statusTer: [],
        terCategoria: [],
        territorio: [],
        municipio: {},
        uf: [],
      };

      this.qtdStatusTer = 0;
      this.qtdTer = 0;
      this.qtdTerCategoria = 0;
      this.qtdMunicipio = 0;
      this.qtdUf = 0;
      this.$forceUpdate();

      this.setLoading(false);
    },
    changeMapColor(event, label) {
      this.filtersColor[label] = event;
      localStorage.setItem(
        "mapFiltersColors",
        JSON.stringify(this.filtersColor)
      );

      this.$emit("changeColor", this.filtersColor);
    },
    filtrarStatus(item) {
      const territoriosComStatus = this.territorios
        .filter((t) =>
          this.filtro.statusTer.some(
            (s) => t.statusId == s.statusId && t.uf == s.uf
          )
        )
        .map((item) => item.territorioId.toString());

      const ter =
        item.territorios && item.territorios.length
          ? item.territorios.split(",")
          : "";

      if (ter && territoriosComStatus.some((item) => ter.includes(item))) {
        item.class = "corRelacionamento";
        this.qtdStatusTer++;
      }

      if (this.filtro.terCategoria.length > 0) {
        this.filtrarCategoria(item);
      }
    },
    filtrarPorUf(item) {
      if (this.filtro.uf.some((val) => val.uf == item.uf && item.possuiTE)) {
        item.class = "corUF";
        this.qtdUf++;

        this.filtrarTerritorio(item);
      }
    },
    filtrarTerritorio(item) {
      if (
        this.filtro.territorio.some((val) =>
          item.territorios.includes(val.territorioId)
        )
      ) {
        item.class = "corUR";
        this.qtdTer++;

        if (this.filtro.statusTer.length > 0) {
          this.filtrarStatus(item);
        }
      }
    },
    filtrarCategoria(item) {
      if (
        this.filtro.terCategoria.some((val) =>
          item.categorias.includes(val.territorioCategoriaId)
        )
      ) {
        item.class = "corEixo";
        this.qtdTerCategoria++;
      }
    },
    filtrarPorMunicipio(item) {
      if (this.filtro.municipio.codigoIbge == item.codigoIBGE) {
        item.class = "corMunicipio";
        this.qtdMunicipio++;
      }
    },
    filtrar() {
      if (
        !Object.values(this.filtro).find((item) => item.length > 0) &&
        !this.filtro.municipio.codigoIbge
      ) {
        return;
      }

      this.setLoading(true);
      this.resetarMapaFixo();

      this.auxMapa = this.mapa.mapaFixo;

      this.qtdStatusTer = 0;
      this.qtdTer = 0;
      this.qtdTerCategoria = 0;
      this.qtdMunicipio = 0;
      this.qtdUf = 0;

      this.auxMapa.forEach((item) => {
        if (this.filtro.uf.length) {
          this.filtrarPorUf(item);
        }
        if (this.filtro.territorio.length) {
          this.filtrarTerritorio(item);
        } else if (this.filtro.statusTer.length) {
          this.filtrarStatus(item);
        } else if (this.filtro.terCategoria.length) {
          this.filtrarCategoria(item);
        }

        if (this.filtro.municipio) {
          this.filtrarPorMunicipio(item);
        }
      });

      this.setMapaVariables({
        mapaFixo: this.auxMapa,
        totalMapaTerritorio: this.qtdTer,
        totalMapaTerCategoria: this.qtdTerCategoria,
        totalMapaMunicipio: this.qtdMunicipio,
        totalMapaTerStatus: this.qtdStatusTer,
        totalMapaUfs: this.qtdUf,
        filter: {
          territorio: this.filtro.territorio,
          terCategoria: this.filtro.terCategoria,
          municipio: this.filtro.municipio,
          terStatus: this.filtro.statusTer,
          uf: this.filtro.uf,
        },
      });

      this.setLoading(false);
    },
  },
};
</script>
<style lang="scss" scoped>
.filtro-item {
  padding-bottom: 10px;
}

.eixo {
  position: relative;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.38);
  padding: 10px;
  h1 {
    position: absolute;
    color: rgb(0 0 0 / 41%);
    top: -13px;
    left: 10px;
    background: #fff;
    font-size: 16px;
    padding: 0 10px;
  }
}
</style>
