import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAutocomplete,{attrs:{"item-text":_vm.itemLabel,"item-value":_vm.itemValue,"items":_vm.items,"label":_vm.label,"placeholder":"Selecione...","return-object":true,"disabled":_vm.disabled,"multiple":"","outlined":_vm.outlined,"hide-details":"auto"},on:{"change":_vm.emitValue},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index < _vm.maxDisplay)?_c(VChip,_vm._b({attrs:{"small":""}},'v-chip',item,false),[_c('span',{staticClass:"textoTemas"},[_vm._v(_vm._s(item[_vm.itemLabel]))])]):_vm._e(),(index === _vm.maxDisplay)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.value.length - _vm.maxDisplay)+" "+_vm._s(_vm.label)+")")]):_vm._e()]}}]),model:{value:(_vm.defaultValue),callback:function ($$v) {_vm.defaultValue=$$v},expression:"defaultValue"}},[_c('div',{staticStyle:{"display":"flex","padding":"0 16px","cursor":"pointer"},attrs:{"slot":"prepend-item","ripple":""},on:{"click":_vm.toggle},slot:"prepend-item"},[_c('div',{staticStyle:{"margin-right":"32px"}},[_c(VIcon,{attrs:{"color":_vm.value.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.icon))])],1),_c('div',[_vm._v("Todos")])]),_c(VDivider,{staticClass:"mt-2",attrs:{"slot":"prepend-item"},slot:"prepend-item"}),_c('template',{slot:"no-data"},[(_vm.loading)?_c('div',[_c(VCol,{attrs:{"align-self":"center"}},[(_vm.loading)?_c('div',{staticClass:"progress"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()])],1):_c('div',{staticClass:"noValues"},[_vm._v("Nenhum dado encontrado")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }