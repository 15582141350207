<template>
  <v-autocomplete
    v-model="defaultValue"
    :item-text="itemLabel"
    :item-value="itemValue"
    :items="items"
    :label="label"
    placeholder="Selecione..."
    :return-object="true"
    :disabled="disabled"
    multiple
    :outlined="outlined"
    @change="emitValue"
    hide-details="auto"
  >
    <div
      style="display: flex; padding: 0 16px; cursor: pointer"
      slot="prepend-item"
      ripple
      @click="toggle"
    >
      <div style="margin-right: 32px">
        <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">{{
          icon
        }}</v-icon>
      </div>
      <div>Todos</div>
    </div>
    <v-divider slot="prepend-item" class="mt-2" />
    <template v-slot:selection="{ item, index }">
      <v-chip v-bind="item" small v-if="index < maxDisplay">
        <span class="textoTemas">{{ item[itemLabel] }}</span>
      </v-chip>
      <span v-if="index === maxDisplay" class="grey--text caption"
        >(+{{ value.length - maxDisplay }} {{ label }})</span
      >
    </template>
    <template slot="no-data">
      <div v-if="loading">
        <v-col align-self="center"
          ><div class="progress" v-if="loading">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular></div
        ></v-col>
      </div>
      <div v-else class="noValues">Nenhum dado encontrado</div>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: "ComboboxGenerica",
  props: {
    value: { type: Array, required: true },
    items: { type: Array, required: true },
    label: { type: String, required: true },
    disabled: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    itemValue: { type: [String, Number], required: true },
    itemLabel: { type: [String, Number], required: true },
    outlined: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      defaultValue: this.value,
      maxDisplay: 1,
    };
  },
  computed: {
    likesAll() {
      return this.value.length === this.items.length;
    },
    likesSome() {
      return this.value.length > 0 && !this.items;
    },
    icon() {
      if (this.likesAll) return "mdi-close-box";
      if (this.likesSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.defaultValue = [];
          this.$emit("change", this.defaultValue);
        } else {
          this.defaultValue = this.items.slice();
          this.$emit("change", this.defaultValue);
        }
      });
    },
    emitValue(e) {
      this.defaultValue = e;
      this.$emit("change", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.noValues {
  padding: 10px;
  text-align: center;
}
</style>
